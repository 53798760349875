import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CellValueChangedEvent, RowSelectedEvent } from 'ag-grid-community';

import { getCashierTransactionCols } from 'constants/ag-grid-table';
import { useGetGuestOrderDetail } from 'hooks/useGetGuestTransactions';
import { formatCurrency } from 'utils';
import { useMutation } from '@tanstack/react-query';
import { editTransactionNote } from 'services/api/module/booking.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { notification } from 'antd';

function TransactionTable({
  orderId,
  setTransactionIds
}: {
  orderId: number;
  setTransactionIds: (selectedKey: any) => void;
}) {
  const { data: orderDetail } = useGetGuestOrderDetail(orderId);

  const { mutateAsync: mutateEditTransactionNode } = useMutation({
    mutationFn: (params: any) => editTransactionNote(params)
  });

  const handleChangeSelectedRow = (event: RowSelectedEvent) => {
    const transactionIds = event.api
      .getSelectedNodes()
      .filter(item => !item.data.is_deleted)
      .map(item => item.data.id);
    setTransactionIds(transactionIds);
  };

  const handleChangeCellValue = async (event: CellValueChangedEvent) => {
    if (event.colDef.field === 'note') {
      const updatedNotice = event.newValue;
      const transactionId = event.data.id;
      try {
        await mutateEditTransactionNode({ transaction_id: transactionId, note: updatedNotice });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
        });
        notification.success({
          message: 'Thay đổi ghi chú thành công'
        });
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  const cashierTransactionCols = useMemo(() => {
    return getCashierTransactionCols();
  }, []);

  return (
    <div className="pmn-cashier__transaction-table ag-theme-quartz">
      <AgGridReact
        rowData={orderDetail?.transactions}
        columnDefs={cashierTransactionCols}
        rowSelection="multiple"
        // loadingOverlayComponent={isFetchingRoomTypes}
        // loadingCellRenderer={isFetchingRoomTypes}
        onCellValueChanged={handleChangeCellValue}
        onRowSelected={handleChangeSelectedRow}
        stopEditingWhenCellsLoseFocus
      />

      <div style={{ marginTop: 12 }}>
        <h5 className="m-0" style={{ fontWeight: 600 }}>
          Balance: <span className="text-error">{formatCurrency(orderDetail?.balance || 0)}</span>
        </h5>
      </div>
    </div>
  );
}

export default TransactionTable;
