import React from 'react';
import { Modal, Form, Card, Row, Col, DatePicker, Select, InputNumber, Input } from 'antd';
import {
  BookingLine,
  TransactionCodesResponse,
  TransactionData
} from 'services/api/type/booking.type';
import { formatInputNumber } from 'utils/currency';
import { MyInfoType } from 'services/api/type/user.type';

interface RenderModalProps {
  isOpen: boolean;
  handleClose: () => void;
  form: any;
  onFinish: (values: any) => void;
  isPending: boolean;
  folio_balance_code?: string;
  guest_id: number | undefined;
  bookingLineDetail?: BookingLine;
  userData?: MyInfoType;
  transactionCodes?: TransactionCodesResponse[];
  orderDetails?: TransactionData;
  uniqueTransactionCodesName: Set<string[] | undefined>;
  title: string;
}

export const RenderPostTransactionModal = ({
  isOpen,
  handleClose,
  form,
  onFinish,
  isPending,
  folio_balance_code,
  guest_id,
  bookingLineDetail,
  userData,
  transactionCodes,
  orderDetails,
  uniqueTransactionCodesName,
  title
}: RenderModalProps) => (
  <Modal
    title={title}
    centered
    width={900}
    open={isOpen}
    onCancel={handleClose}
    okText="Lưu"
    okButtonProps={{ className: 'ant-btn-secondary' }}
    onOk={() => form.submit()}
    cancelText="Đóng"
    className="modal-change-price post-payment-transaction-modal"
    confirmLoading={isPending}
    destroyOnClose
  >
    <Form
      form={form}
      layout="horizontal"
      onFinish={onFinish}
      autoComplete="off"
      className="post-payment-transaction-form"
    >
      <Card className="post-payment-transaction-card">
        <Row gutter={[16, 0]} className="post-payment-transaction-card__row">
          <Col span={12} className="post-payment-transaction-card__col-left">
            <div className="post-payment-transaction-card__body-left">
              <Row gutter={[8, 0]} className="w-full equal-spacing-row">
                <Col span={24} className="post-payment-transaction-card__col">
                  <Form.Item
                    label="Date"
                    name="transaction_date"
                    rules={[{ required: true, message: 'Please select date' }]}
                    labelCol={{ span: 10, style: { paddingRight: '0' } }}
                  >
                    <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col span={24} className="post-payment-transaction-card__col">
                  <Form.Item
                    label="Guest Name"
                    labelCol={{ span: 10, style: { paddingRight: '0' } }}
                  >
                    <div className="post-payment-transaction-card__value">
                      {bookingLineDetail?.booking_line_guests
                        .filter(item => item.id === guest_id)
                        .map(item => item.guest_name)}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={24} className="post-payment-transaction-card__col">
                  <Form.Item label="Cashier" labelCol={{ span: 10, style: { paddingRight: '0' } }}>
                    <div className="post-payment-transaction-card__value">{userData?.name}</div>
                  </Form.Item>
                </Col>
                <Col span={24} className="post-payment-transaction-card__col">
                  <Form.Item
                    label={<strong>Type</strong>}
                    name="transaction_code_id"
                    rules={[{ required: true, message: 'Please select a transaction type' }]}
                    labelCol={{ span: 10, style: { paddingRight: '0' } }}
                  >
                    <Select style={{ width: '100%' }} placeholder="Select Transaction Type">
                      {Array.from(uniqueTransactionCodesName)
                        .flat()
                        .map(name => {
                          const matchingCode = transactionCodes?.find(code => code.name === name);
                          return (
                            matchingCode && (
                              <Select.Option key={matchingCode.id} value={matchingCode.id}>
                                {name}
                              </Select.Option>
                            )
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>

          <Col span={12} className="post-payment-transaction-card__col-right">
            <div className="post-payment-transaction-card__body-right">
              <Row gutter={[8, 0]} className="w-full equal-spacing-row">
                <Col span={24} className="post-payment-transaction-card__col">
                  <Form.Item
                    label="Transaction Page"
                    labelCol={{ span: 10, style: { paddingRight: '0' } }}
                  >
                    <div className="post-payment-transaction-card__value">{folio_balance_code}</div>
                  </Form.Item>
                </Col>
                <Col span={24} className="post-payment-transaction-card__col">
                  <Form.Item label="Balance" labelCol={{ span: 10, style: { paddingRight: '0' } }}>
                    <div className="post-payment-transaction-card__value">
                      {formatInputNumber(orderDetails?.balance || 0)}
                    </div>
                  </Form.Item>
                </Col>
                <Col span={24} className="post-payment-transaction-card__col">
                  <Form.Item
                    label={<strong>Payment Amount</strong>}
                    name="amount"
                    labelCol={{ span: 10, style: { paddingRight: '0' } }}
                  >
                    <InputNumber
                      defaultValue={0}
                      formatter={value => formatInputNumber(value)}
                      style={{ width: '100%' }}
                      addonAfter="VND"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24} className="post-payment-transaction-note-row">
            <Form.Item label="Note" name="note">
              <Input.TextArea placeholder="Note..." rows={3} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  </Modal>
);
