import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import {
  PaginationType,
  ParamsGetTransactionsType,
  TransactionCodesResponse,
  TransactionData
} from 'services/api/type/booking.type';
import { axiosInstance } from 'services/api/base.api';
import { transactionCode } from 'services/api/module/booking.api';

export const LIMIT_GROUP_LIST = 10;

const groupByParty = (data: any) => {
  return (data || []).reduce((acc: any, booking: any) => {
    const lastItem = acc[acc.length - 1];

    // Group all bookings with a party_id
    if (!lastItem || lastItem.party_id !== booking.party_id) {
      // Insert 'is_party' for a new party group
      acc.push({
        is_party: true,
        party_id: booking.party_id || 'FIT',
        group_id: booking.group_id
      });
    }
    // Push the booking itself
    acc.push(booking);

    return acc;
  }, []);
};

export const useGetGuestTransactions = (
  filterOptions?: ParamsGetTransactionsType,
  page: number = 1,
  search?: string
) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_GUEST, page, search, filterOptions],
    queryFn: () => axiosInstance.get('/guests', { params: filterOptions }).then(item => item.data)
  });

  return {
    data: groupByParty(data?.data) || [],
    pagination: _.get(data?.data, 'pagination', {}) as PaginationType,
    isLoading
  };
};

export const useGetGuestOrders = (guest_id: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, guest_id],
    queryFn: () => axiosInstance.get(`/guests/${guest_id}/orders`).then(item => item.data?.data),
    enabled: !!guest_id
  });
};

export const useGetGuestOrderDetail = (order_id?: number) => {
  return useQuery<TransactionData>({
    queryKey: [
      QUERY_KEYS.GET_GUEST_TRANSACTIONS,
      QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL,
      order_id
    ],
    queryFn: () => axiosInstance.get(`/guests/orders/${order_id}`).then(item => item.data?.data),
    enabled: !!order_id
  });
};

export const useGetTransactionCodes = (enable: boolean = true) => {
  return useQuery<TransactionCodesResponse[]>({
    queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL, enable],
    queryFn: () => transactionCode()
  });
};
