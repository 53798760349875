import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Form, Input, Button, Table, Card } from 'antd';
import { generateExportFileName, getEarliestValidDate, toUtcTime } from 'utils';
import { FormProps } from 'rc-field-form';
import _ from 'lodash';
import 'styles/consolidate-revenue.scss';
import { columnsConsolidateRevenue } from 'constants/table';
import { useGetConsolidateRevenue } from 'hooks/uesGetConsolidateRevenue';
import { ExportConsolidateRevenue } from 'components/common/export-consolidate-revenue';

const INITIAL_RANGE_DATE = 14;
const initStartDate = dayjs().startOf('day');

function ConsolidateRevenue() {
  const [form] = Form.useForm();

  const [filterOptions, setFilterOptions] = useState<any>({
    start_date: toUtcTime(initStartDate.add(1, 'day'), 'YYYY-MM-DD'),
    period: INITIAL_RANGE_DATE
  });

  const { data: consolidateRevenue, isLoading } = useGetConsolidateRevenue(filterOptions);

  const transformDataToTable = (data: any) => {
    const transformedData = Object.keys(data).map(roomType => ({
      key: roomType,
      roomType,
      total_night: data[roomType]?.occupied?.total_night || 0,
      occupied_percent: data[roomType]?.occupied?.occupied_percent || 0,
      room_price: data[roomType]?.financials?.room_price || 0,
      service_cost: data[roomType]?.financials?.service_cost || 0,
      revenue: data[roomType]?.financials?.revenue || 0,
      average_price: data[roomType]?.financials?.average_price || 0
    }));

    const validEntriesCount = transformedData.filter(
      row => typeof row.occupied_percent === 'number'
    ).length;

    const totalRow = transformedData.reduce(
      (totals, row) => {
        return {
          key: 'Tổng',
          roomType: 'Tổng',
          total_night: totals.total_night + row.total_night,
          occupied_percent: totals.occupied_percent + row.occupied_percent,
          room_price: totals.room_price + row.room_price,
          service_cost: totals.service_cost + row.service_cost,
          revenue: totals.revenue + row.revenue,
          average_price: totals.average_price + row.average_price
        };
      },
      {
        key: 'Tổng',
        roomType: 'Tổng',
        total_night: 0,
        occupied_percent: 0,
        room_price: 0,
        service_cost: 0,
        revenue: 0,
        average_price: 0
      }
    );

    totalRow.occupied_percent =
      validEntriesCount > 0
        ? parseFloat((totalRow.occupied_percent / validEntriesCount).toFixed(6))
        : 0;

    return [...transformedData, totalRow];
  };

  const dataSource = transformDataToTable(consolidateRevenue || []);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, period, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];

      if (startDate && endDate) {
        const dayCount = endDate.diff(startDate, 'day');
        _.set(restFilterOptions, 'period', dayCount);
        _.set(restFilterOptions, 'start_date', toUtcTime(startDate.add(1, 'day'), 'YYYY-MM-DD'));
      }
    }

    _.set(restFilterOptions, 'period', period || INITIAL_RANGE_DATE);

    setFilterOptions(restFilterOptions);
  };

  const handleRangeDateChange = (dates: any) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      const dayCount = end.diff(start, 'day');
      form.setFieldsValue({ period: dayCount });
    }
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const period = parseInt(e.target.value);
    if (!isNaN(period)) {
      const startDate = dayjs(form.getFieldValue('rangeDate')[0]);
      const endDate = startDate.add(period, 'day');
      form.setFieldsValue({ rangeDate: [startDate, endDate] });
    }
  };

  const fromDate = getEarliestValidDate(filterOptions.start_date);

  const toDate = filterOptions.start_date
    ? dayjs(filterOptions.start_date)
        .add(filterOptions.period, 'day')
        .subtract(1, 'day')
        .format('DD-MM-YYYY')
    : undefined;

  return (
    <div className="pms-consolidate-revenue">
      <Card title="Báo cáo Doanh thu tổng hợp">
        <div className="pms-consolidate-revenue__timetable">
          <Form
            form={form}
            initialValues={{
              rangeDate: [initStartDate, initStartDate.add(INITIAL_RANGE_DATE, 'day')],
              period: INITIAL_RANGE_DATE
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className="pms-consolidate-revenue__filter">
              <div className="pms-consolidate-revenue__filter__left">
                <Form.Item name="rangeDate">
                  <DatePicker.RangePicker
                    className="w-full"
                    placeholder={['Từ ngày', 'Đến ngày']}
                    format="DD-MM-YYYY"
                    onChange={handleRangeDateChange}
                  />
                </Form.Item>
                <Form.Item name="period">
                  <Input placeholder="Số ngày" onChange={handlePeriodChange} />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                    Tìm kiếm
                  </Button>
                </Form.Item>
              </div>
              <ExportConsolidateRevenue
                fileName={generateExportFileName('consolidate-revenue')}
                fromDate={fromDate}
                toDate={toDate}
                dataToExport={dataSource}
              />
            </div>
          </Form>
          <div className="pms-consolidate-revenue__table">
            <Table
              loading={isLoading}
              rowKey="id"
              size="small"
              columns={columnsConsolidateRevenue}
              dataSource={dataSource ?? []}
              rowClassName={record => (record.roomType === 'Tổng' ? 'bold-row' : '')}
              bordered
              pagination={false}
              locale={{
                emptyText: <span className="empty-data">Không có dữ liệu</span>
              }}
              scroll={{ x: 1200, y: 'calc(100vh - 400px)' }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default ConsolidateRevenue;
