import { create } from 'zustand';

export interface CashierType {
  selectedReservationId: string;
  setSelectedReservationId: (id: string) => void;
}

const useCashierStore = create<CashierType>(set => ({
  selectedReservationId: '',
  setSelectedReservationId: (id: string) => set(() => ({ selectedReservationId: id }))
}));

export default useCashierStore;
