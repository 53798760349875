import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Button, Card, Input, Form, FormProps, Table, notification, DatePicker } from 'antd';
import { useMutation } from '@tanstack/react-query';

import { useGetRoomLock } from 'hooks/useGetRoomLock';
import useBookingStore from 'stores/useBooking';
import useModal from 'stores/useModal';

import { getColumnRoomLock } from 'constants/table';
import { PAGE_SIZE_ROOM_LOCKS, unlockRoom } from 'services/api/module/room.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import 'styles/room-lock.scss';
import { Dayjs } from 'dayjs';
import { handleRangeDateChange } from 'utils';

function RoomLock() {
  const [form] = Form.useForm();
  const { setRoom } = useBookingStore();
  const { setIsOpenFixRoom, setInfoConfirmModal, setConfirmLoading } = useModal();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_ROOM_LOCKS);
  const [filterOptions, setFilterOptions] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { data, pagination, isFetching } = useGetRoomLock(filterOptions);

  const { mutateAsync: mutateUnlock } = useMutation({
    mutationFn: (lock_ids: React.Key[]) => unlockRoom({ lock_ids })
  });

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});
    const { rangeDate, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const checkInFrom: Dayjs = rangeDate[0];
      const checkInTo: Dayjs = rangeDate[1];
      if (checkInFrom) {
        _.set(restFilterOptions, 'start_date', checkInFrom.format('YYYY-MM-DD HH:mm:ss'));
      }
      if (checkInTo) {
        _.set(restFilterOptions, 'end_date', checkInTo.format('YYYY-MM-DD HH:mm:ss'));
      }
    }
    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleUnlockSelected = () => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận mở khóa phòng',
      okText: 'Xác nhận',
      onOk: async () => {
        if (!_.isEmpty(selectedRowKeys)) {
          try {
            setConfirmLoading(true);
            await mutateUnlock(selectedRowKeys);

            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.GET_ROOM_LOCK]
            });
            setInfoConfirmModal(false);
            setSelectedRowKeys([]);
            notification.success({
              message: 'Mở khóa phòng thành công'
            });
          } catch (err: any) {
            notification.error({
              message: 'Mở khóa phòng thất bại'
            });
          } finally {
            setConfirmLoading(false);
          }
        }
      }
    });
  };

  const handleOpenFixRoomModal = () => {
    setIsOpenFixRoom(true, /* isCreateFixRoom = */ true);
  };
  const handleChangePage = (page: number) => {
    setPage(page);
    setFilterOptions({ ...filterOptions, page, limit: pageSize });
  };

  const columnRoomLock = useMemo(() => {
    const handleEdit = (roomId: number) => {
      const roomInfo = data?.find(room => room.room_id === roomId);
      if (roomInfo) {
        setRoom(roomId, roomInfo.room_name, {
          start_date: roomInfo.start_date,
          end_date: roomInfo.end_date,
          reason: roomInfo.reason,
          lock_id: roomInfo.id
        });
        setIsOpenFixRoom(true);
      }
    };

    return getColumnRoomLock(handleEdit);
  }, [data, setIsOpenFixRoom, setRoom]);

  return (
    <div className="pms-room-lock">
      <Card title="Tất cả" style={{ width: '100%' }}>
        <Form
          form={form}
          name="room-lock-filter-form"
          layout="inline"
          initialValues={{
            booking_id: '',
            rangeDate: null,
            status: ''
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="pms-room-lock__filter">
            <Form.Item name="room_id" style={{ marginRight: 0 }}>
              <Input placeholder="Mã phòng" />
            </Form.Item>
            <Form.Item name="rangeDate">
              <DatePicker.RangePicker
                className="w-full"
                placeholder={['Ngày giờ bắt đầu', 'Ngày giờ kết thúc']}
                format="DD-MM-YYYY HH:mm"
                onChange={value => {
                  handleRangeDateChange(form, value);
                }}
              />
            </Form.Item>

            <Button htmlType="submit" className="ant-btn-secondary btn-submit">
              Tìm kiếm
            </Button>

            <Button
              type="primary"
              onClick={handleUnlockSelected}
              disabled={selectedRowKeys.length === 0}
            >
              Mở khóa phòng
            </Button>

            <Button type="primary" onClick={handleOpenFixRoomModal}>
              Tạo khóa phòng
            </Button>
          </div>
        </Form>

        <div className="pms-room-lock__table">
          <Table
            loading={isFetching}
            rowKey="id"
            columns={columnRoomLock}
            dataSource={data}
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
            scroll={{ x: 500, y: 'calc(100vh - 400px)' }}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange
            }}
            pagination={{
              current: page,
              position: ['bottomCenter'],
              total: pagination.total,
              pageSize: pageSize,
              locale: { items_per_page: '/ trang' },
              showLessItems: true,
              showSizeChanger: false,
              showTotal: total => `${total} items`,
              onChange: (page: number) => handleChangePage(page),
              onShowSizeChange: (_, size) => {
                setPageSize(size);
              }
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default RoomLock;
