import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Col, DatePicker, Form, FormProps, Modal, notification, Row, Select } from 'antd';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import useModal from 'stores/useModal';

import { ReactComponent as IconCancelCalendar } from 'assets/images/cancel-calendar.svg';
import { ReactComponent as IconBedUser } from 'assets/images/bed-user.svg';
import { BookingStatus, FOLIO_BALANCE_CODE } from 'services/api/type/booking.type';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { postRoomCharge } from 'services/api/module/booking.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';

function PostRoomChargeModal() {
  const [form] = Form.useForm();

  const {
    postRoomCharge: { isOpenPostRoomCharge, bookingLineId },
    closePostRoomCharge
  } = useModal();

  const { data: bookingLineDetail } = useGetBookingDetail(bookingLineId);

  const { mutateAsync: mutatePostRoomCharge, isPending } = useMutation({
    mutationFn: (params: any = {}) =>
      postRoomCharge({
        id: Number(bookingLineId),
        from_date: params.from_date,
        to_date: params.to_date,
        folio_balance_code: params.folio_balance_code
      })
  });

  useEffect(() => {
    if (bookingLineId) {
      form.resetFields();
    }
  }, [form, bookingLineId]);

  const handleCloseModal = () => {
    closePostRoomCharge();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      await mutatePostRoomCharge({
        from_date: values.from_date.format('YYYY-MM-DD'),
        to_date: values.to_date.format('YYYY-MM-DD'),
        folio_balance_code: values.folio_balance_code
      });
      closePostRoomCharge();
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const minDate = useMemo(
    () => (bookingLineDetail?.check_in_date ? dayjs(bookingLineDetail?.check_in_date) : undefined),
    [bookingLineDetail?.check_in_date]
  );

  const maxDate = useMemo(
    () =>
      bookingLineDetail?.check_out_date ? dayjs(bookingLineDetail?.check_out_date) : undefined,
    [bookingLineDetail?.check_out_date]
  );

  return (
    <Modal
      title={
        <div className="modal-change-price-header">
          <h3 className="modal-change-price-header__title">Post Room Charge</h3>
          <div className="modal-change-price-header__info">
            <div className="flex items-center" style={{ gap: '4px' }}>
              {bookingLineDetail?.status === BookingStatus.CHECK_IN ? (
                <IconBedUser />
              ) : (
                <IconCancelCalendar />
              )}
              <span style={{ lineHeight: 'normal' }}>
                {bookingLineDetail?.room_name || bookingLineDetail?.room_type_name + ' N/A'}
              </span>
            </div>

            <p className="modal-change-price-header__customer-name">
              {bookingLineDetail?.partner_name || 'Guest'}
            </p>
          </div>
        </div>
      }
      centered
      width={500}
      open={isOpenPostRoomCharge}
      onCancel={handleCloseModal}
      okText="Lưu"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={() => form.submit()}
      cancelText="Đóng"
      closeIcon={null}
      className={classNames('modal-change-price')}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        name="change-price-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          from_date: dayjs(),
          to_date: dayjs(),
          folio_balance_code: 'A'
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="change-price-col">
          <div className="change-price-preset">
            <Row>
              <Col span={12} className="flex items-center">
                <b>
                  <span>Transaction Page</span>
                </b>
              </Col>
              <Col span={12}>
                <Form.Item name="folio_balance_code" className="m-0 w-full">
                  <Select options={FOLIO_BALANCE_CODE} />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col span={12} className="flex items-center">
                <Form.Item label="From date" name="from_date">
                  <DatePicker
                    format="DD/MM/YYYY"
                    className="w-full"
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="To date" name="to_date" className="m-0 flex-grow">
                  <DatePicker
                    minDate={minDate}
                    maxDate={maxDate}
                    format="DD/MM/YYYY"
                    className="w-full"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </Form>
    </Modal>
  );
}

export default PostRoomChargeModal;
