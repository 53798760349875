import _, { uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import classNames from 'classnames';
import {
  Modal,
  Row,
  Col,
  Button,
  Input,
  Menu,
  MenuProps,
  List,
  Form,
  notification,
  FormProps,
  InputNumber,
  Checkbox,
  FormInstance
} from 'antd';
import {
  PlusCircleOutlined,
  RightOutlined,
  PlusOutlined,
  MinusOutlined,
  CloseCircleFilled,
  EditFilled,
  CheckCircleFilled
} from '@ant-design/icons';

import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';
import { useGetServices } from 'hooks/useGetServices';

import { queryClient } from 'index';
import { BookingServiceType } from 'services/api/type/booking.type';
import { slugify, formatCurrency } from 'utils';
import QUERY_KEYS from 'services/api/queryKeys';
import { MESSAGE_CODE } from 'constants/validate';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import API from 'services/api';
import { formatInputNumber } from 'utils/currency';

const ADDITIONAL_TAB = 'ADDITIONAL_TAB';

function ServiceModal() {
  const [form] = Form.useForm();
  const [formAdditional] = Form.useForm();
  const servicesForm = Form.useWatch('services', form);

  const { bookingLineId } = useBookingStore();
  const { isOpenAddService, setIsOpenAddService } = useModal();

  const { data: bookingLineDetail } = useGetBookingDetail(bookingLineId);
  const { data: services } = useGetServices();

  const [keyword, setKeyword] = useState('');
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');

  const { mutateAsync: mutateAddService, isPending } = useMutation({
    mutationFn: ({
      bookingLineId,
      params
    }: {
      bookingLineId: number;
      params: {
        services: BookingServiceType[];
      };
    }) => API.booking.addServices(bookingLineId, params)
  });

  useEffect(() => {
    if (!isOpenAddService) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenAddService]);

  useEffect(() => {
    if (!_.isEmpty(services)) {
      setTab(_.get(services, '0.category', ''));
      setSelectedSubCategory(_.get(services, '0.sub_category', ''));
    }
  }, [services]);

  const handleChangeKeyword = (e: any) => {
    setKeyword(e.target.value);
    handleChangeSearchHook(e.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeSearchHook = useCallback(
    _.debounce((value: string) => {
      setSearch(value);
    }, 300),
    []
  );

  const handleSelectMenu: MenuProps['onClick'] = e => {
    setSelectedSubCategory(e.key);
  };

  const handleCloseAddService = () => {
    setIsOpenAddService(false);
  };

  const handleSelectTab = (tabKey: string) => {
    setTab(tabKey);
    if (tabKey !== ADDITIONAL_TAB) {
      setSelectedSubCategory(subCategory[tabKey][0]);
    }
  };

  const handleAddItem = (item: BookingServiceType) => {
    const services = _.cloneDeep(form.getFieldValue('services'));
    const foundService: BookingServiceType = _.find(services, { id: item.id });
    if (!foundService) {
      services.push({
        id: item.id,
        name: item.name,
        price: item.price,
        qty: 1,
        allow_foc: item.allow_foc,
        allow_custom_price: item.allow_custom_price
      });
    } else {
      foundService.qty = (foundService?.qty || 0) + 1;
    }
    form.setFieldValue('services', services);
  };

  const handleIncreaseService = (key: (string | number)[]) => {
    form.setFieldValue(key, (form.getFieldValue(key) || 0) + 1);
  };

  const handleDecreaseService = (key: (string | number)[]) => {
    const currentValue = form.getFieldValue(key);
    if (currentValue === 1) {
      return;
    }
    form.setFieldValue(key, form.getFieldValue(key) - 1);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      if (bookingLineDetail?.booking_line_id) {
        await mutateAddService({
          bookingLineId: bookingLineDetail?.booking_line_id,
          params: {
            services: values.services.map((item: any) => ({
              id: item.id,
              is_foc: Boolean(item.is_foc),
              name: item.name,
              price: item.price,
              is_addon_service: item.is_addon_service,
              qty: item.qty,
              note: values.note
            }))
          }
        });
        notification.success({
          message: 'Thêm dịch vụ thành công'
        });
        setIsOpenAddService(false);
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            bookingLineDetail.booking_line_id
          ]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST]
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_CLEANING_CALENDAR]
        });
        form.resetFields();
      }
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const handleIncreaseQTY = (key: string) => {
    formAdditional.setFieldValue(key, (formAdditional.getFieldValue(key) || 0) + 1);
  };

  const handleDecreaseQTY = (key: string) => {
    const currentValue = formAdditional.getFieldValue(key);
    if (!currentValue || currentValue === 1) {
      return;
    }

    formAdditional.setFieldValue(key, formAdditional.getFieldValue(key) - 1);
  };

  const handleAddAdditionalService: FormProps['onFinish'] = async (values: any) => {
    const services = _.cloneDeep(form.getFieldValue('services'));
    services.push({
      is_addon_service: true,
      price: values.price,
      name: values.name,
      qty: values.qty
    });
    form.setFieldValue('services', services);

    formAdditional.resetFields();
  };

  const handleChangeFOC = (value: boolean, fieldName: number, serviceItem: BookingServiceType) => {
    if (value) {
      form.setFieldValue(['services', fieldName, 'price'], 0);
    } else {
      const service = services?.find(item => item.id === serviceItem.id);
      if (service) {
        form.setFieldValue(['services', fieldName, 'price'], service.price);
      }
    }
  };

  const handleChangeCustomPrice = (fieldName: number, customPrice: number) => {
    form.setFieldValue(['services', fieldName, 'price'], customPrice);
  };

  const serviceTabs = useMemo(() => {
    const result: any = [];
    services?.forEach(item => {
      result.push({
        key: item.category,
        label: item.category
      });
    });

    return uniqBy(result, 'key').sort((a: any, b: any) => String(b.label).localeCompare(a.label));
  }, [services]);

  const subCategory = useMemo(() => {
    const result: any = {};
    services?.forEach(item => {
      if (!result[item.category]) {
        _.set(result, item.category, []);
      }
      result[item.category].push(item.sub_category);
      result[item.category] = _.uniq(result[item.category]);
    });
    return result;
  }, [services]);

  const menuSubCategory = useMemo(() => {
    if (subCategory && subCategory[tab]) {
      return subCategory[tab].map((item: string) => ({
        label: (
          <>
            <span>{item}</span>
            <RightOutlined />
          </>
        ),
        key: item
      }));
    }
    return [];
  }, [subCategory, tab]);

  const listItems = useMemo(() => {
    const result: any = {};
    services?.forEach(item => {
      const key = `${item.category}-${item.sub_category}`;
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push({
        id: item.id,
        name: item.name,
        price: item.price,
        allow_foc: item.allow_foc,
        allow_custom_price: item.allow_custom_price
      });
    });
    return result;
  }, [services]);

  const totalMoney = useMemo(() => {
    if (!servicesForm) {
      return 0;
    }
    return servicesForm.reduce((cur: number, next: BookingServiceType) => {
      const total = next.price * (next?.qty || 0);
      return cur + total;
    }, 0);
  }, [servicesForm]);

  const resultSearch = useMemo(() => {
    return services?.filter(item =>
      slugify(item.name).toLowerCase().includes(slugify(search).toLowerCase().trim())
    );
  }, [search, services]);

  return (
    <Modal
      title={
        <div className="modal-service-header">
          <p className="title">Dịch vụ</p>
          {tab !== ADDITIONAL_TAB && (
            <div className="modal-service-header__search">
              <Input
                allowClear
                value={keyword}
                onChange={handleChangeKeyword}
                placeholder="Tìm kiếm tên dịch vụ"
              />
            </div>
          )}
        </div>
      }
      centered
      width={1250}
      open={isOpenAddService}
      onCancel={handleCloseAddService}
      footer={null}
      closeIcon={null}
      className="modal-service"
      destroyOnClose
    >
      <Row className="modal-content-title">
        <Col span={13} className="left-col">
          {!search && (
            <ul className="service-tabs">
              {serviceTabs.map((item: any, index: number) => (
                <li
                  key={index}
                  className={classNames('service-tab-item', {
                    active: tab === item.key
                  })}
                  onClick={() => handleSelectTab(item.key)}
                >
                  {item.label}
                </li>
              ))}
              <li
                className={classNames('service-tab-item', {
                  active: tab === ADDITIONAL_TAB
                })}
                onClick={() => handleSelectTab(ADDITIONAL_TAB)}
              >
                Dịch vụ mở rộng
              </li>
            </ul>
          )}
        </Col>
        <Col span={11} className="flex items-center right-col">
          <p>Chi tiết hoá đơn</p>
        </Col>
      </Row>

      <Row className="modal-content">
        <Col span={13}>
          {tab === ADDITIONAL_TAB ? (
            <Form
              form={formAdditional}
              name="additional-service-form"
              layout="vertical"
              style={{ width: '100%', height: '100%' }}
              initialValues={{
                name: '',
                price: 0,
                qty: 1
              }}
              onFinish={handleAddAdditionalService}
              autoComplete="off"
            >
              <Form.Item
                label="Tên dịch vụ"
                name="name"
                rules={[
                  {
                    required: true,
                    message: MESSAGE_CODE.REQUIRED_SERVICE_NAME
                  }
                ]}
              >
                <Input />
              </Form.Item>

              <Row gutter={[16, 16]}>
                <Col span={18}>
                  <Form.Item
                    label="Đơn giá"
                    name="price"
                    rules={[
                      {
                        required: true,
                        message: MESSAGE_CODE.REQUIRED_SERVICE_NAME_PRICE
                      }
                    ]}
                  >
                    <InputNumber
                      min={0}
                      className="w-full"
                      formatter={value => formatInputNumber(value)}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="qty"
                    label={<span style={{ visibility: 'hidden' }}>Số lượng</span>}
                  >
                    <InputNumber
                      min={1}
                      addonBefore={
                        <MinusOutlined
                          onClick={() => {
                            handleDecreaseQTY('qty');
                          }}
                        />
                      }
                      addonAfter={
                        <PlusOutlined
                          onClick={() => {
                            handleIncreaseQTY('qty');
                          }}
                        />
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="text-center">
                <Button className="ant-btn-secondary" icon={<PlusOutlined />} htmlType="submit">
                  Thêm vào hóa đơn
                </Button>
              </div>
            </Form>
          ) : (
            <div className="menu-items">
              {!search ? (
                <>
                  <Menu
                    onClick={handleSelectMenu}
                    selectedKeys={[selectedSubCategory]}
                    mode="vertical"
                    items={menuSubCategory}
                  />

                  <List
                    style={{
                      maxHeight: 500,
                      overflow: 'auto',
                      scrollbarWidth: 'thin'
                    }}
                    itemLayout="horizontal"
                    dataSource={listItems[`${tab}-${selectedSubCategory}`]}
                    renderItem={(item: BookingServiceType, index) => (
                      <List.Item key={index}>
                        <Button type="text" onClick={() => handleAddItem(item)}>
                          <span>{item.name}</span>
                          <span>{formatCurrency(item.price)}</span>
                        </Button>
                      </List.Item>
                    )}
                  />
                </>
              ) : (
                <List
                  style={{
                    maxHeight: 500,
                    overflow: 'auto',
                    scrollbarWidth: 'thin'
                  }}
                  itemLayout="horizontal"
                  dataSource={resultSearch}
                  renderItem={(item: BookingServiceType, index) => (
                    <List.Item key={index}>
                      <Button type="text" onClick={() => handleAddItem(item)}>
                        <span>{item.name}</span>
                        <span>{formatCurrency(item.price)}</span>
                      </Button>
                    </List.Item>
                  )}
                />
              )}
            </div>
          )}
        </Col>

        <Col span={11} className="bill">
          <Form
            form={form}
            name="service-form"
            layout="vertical"
            style={{ width: '100%', height: '100%' }}
            initialValues={{
              services: []
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.List name="services">
              {(fields, { remove }) => {
                return (
                  <div
                    className="service-item-container"
                    style={{
                      height: !servicesForm?.length ? 0 : '',
                      padding: !servicesForm?.length ? 0 : ''
                    }}
                  >
                    {fields.map(field => {
                      return (
                        <ServiceItem
                          key={field.name}
                          form={form}
                          field={field}
                          onIncreaseService={handleIncreaseService}
                          onDecreaseService={handleDecreaseService}
                          onChangeFOC={handleChangeFOC}
                          onRemove={remove}
                          onCustomPrice={handleChangeCustomPrice}
                        />
                      );
                    })}
                  </div>
                );
              }}
            </Form.List>
            {!servicesForm?.length && (
              <div className="empty-item">
                <span>Chưa có dịch vụ được thêm</span>
              </div>
            )}

            <div className="total-bill">
              <div className="note">
                <Form.Item name="note" className="m-0" style={{ marginBottom: '8px' }}>
                  <Input placeholder="Ghi chú" />
                </Form.Item>
              </div>
              <div className="total-money">
                <span className="title">Tổng tiền</span>
                <span className="number">{Number(totalMoney).toLocaleString('vn')}</span>
              </div>
              <Button
                disabled={!servicesForm?.length}
                className="w-full ant-btn-secondary"
                htmlType="submit"
                loading={isPending}
              >
                <PlusCircleOutlined />
                Thêm vào phòng
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
}

interface ServiceItemProps {
  form: FormInstance;
  field: any;
  onDecreaseService: (fieldName: any[]) => void;
  onIncreaseService: (fieldName: any[]) => void;
  onChangeFOC: (check: boolean, fieldName: number, serviceItem: any) => void;
  onRemove: (fieldName: any[]) => void;
  onCustomPrice?: (fieldName: number, price: number) => void;
}

function ServiceItem({
  form,
  field,
  onDecreaseService,
  onIncreaseService,
  onChangeFOC,
  onRemove,
  onCustomPrice
}: ServiceItemProps) {
  const serviceItem = form.getFieldValue(['services', field.name]);
  const [isShowCustomPrice, setIsShowCustomerPrice] = useState(false);
  const [price, setPrice] = useState(serviceItem.price || 0);
  const handleShowCustomPrice = () => {
    setIsShowCustomerPrice(true);
  };

  const handleChangePrice = (value: number | null) => {
    if (value) {
      setPrice(value);
    }
  };

  const handleSaveCustomPrice = () => {
    setIsShowCustomerPrice(false);
    onCustomPrice && onCustomPrice(field.name, price);
  };

  return (
    <div
      key={field.name}
      className={classNames('service-item', {
        customizing: isShowCustomPrice
      })}
    >
      <p className="service-item__name m-0 truncate" title={serviceItem?.name}>
        {serviceItem?.name}
      </p>
      <Form.Item name={[field.name, 'qty']} className="m-0">
        <InputNumber
          controls={false}
          min={1}
          addonBefore={
            <MinusOutlined
              onClick={() => {
                onDecreaseService(['services', field.name, 'qty']);
              }}
            />
          }
          addonAfter={
            <PlusOutlined
              onClick={() => {
                onIncreaseService(['services', field.name, 'qty']);
              }}
            />
          }
        />
      </Form.Item>
      {serviceItem.allow_foc && (
        <Form.Item valuePropName="checked" name={[field.name, 'is_foc']} className="m-0">
          <Checkbox onChange={e => onChangeFOC(e.target.checked, field.name, serviceItem)}>
            FOC
          </Checkbox>
        </Form.Item>
      )}

      <p className="service-item__price m-0 truncate">
        {Number(serviceItem?.price).toLocaleString('vn')}
      </p>

      <div className="input-custom-price">
        <InputNumber
          value={price}
          onChange={handleChangePrice}
          formatter={value => formatInputNumber(value)}
        />
        <Button
          type="link"
          icon={
            <CheckCircleFilled
              style={{
                fontSize: '18px',
                color: 'rgb(82, 196, 26)'
              }}
            />
          }
          onClick={handleSaveCustomPrice}
        />
      </div>

      <div className="btn-group">
        {serviceItem.allow_custom_price && (
          <div className="btn-edit">
            <Button
              type="link"
              icon={
                <EditFilled
                  style={{
                    fontSize: '18px',
                    color: '#555'
                  }}
                />
              }
              onClick={handleShowCustomPrice}
            />
          </div>
        )}
        <div className="btn-remove">
          <Button
            type="link"
            icon={
              <CloseCircleFilled
                style={{
                  fontSize: '18px',
                  color: '#d9390d'
                }}
              />
            }
            onClick={() => onRemove(field.name)}
          />
        </div>
      </div>
    </div>
  );
}

export default ServiceModal;
