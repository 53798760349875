import { Button, Card, Dropdown, Empty, MenuProps, Tabs } from 'antd';
import TransactionTable from 'components/cashier/transaction-table';
import React, { useState } from 'react';
import { CaretDownFilled } from '@ant-design/icons';
import { TRANSACTION_PROCESSING_ACTION } from 'constants/cashier';
import useModal from 'stores/useModal';
import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

interface CashierTransactionProps {
  orders: any[];
  booking_line_id?: number;
  guest_id?: number;
}

const items: MenuProps['items'] = [
  // {
  //   key: TRANSACTION_PROCESSING_ACTION.VIEW_DETAIL,
  //   label: 'View Transaction Detail'
  // },
  {
    key: TRANSACTION_PROCESSING_ACTION.EDIT_TRANSACTION,
    label: 'Edit Transaction'
  },
  {
    key: TRANSACTION_PROCESSING_ACTION.VOID_TRANSACTION,
    label: 'Void Transaction'
  },
  {
    key: TRANSACTION_PROCESSING_ACTION.MOVE_TRANSACTION,
    label: 'Move Transaction'
  }
  // ,{
  //   key: TRANSACTION_PROCESSING_ACTION.SPLIT_TRANSACTION,
  //   label: 'Split Transaction'
  // }
];

export const CashierTransaction = (props: CashierTransactionProps) => {
  const { orders, guest_id, booking_line_id } = props;
  const {
    openPostRoomCharge,
    openMoveTransaction,
    openPostPaymentTransaction,
    openCancelTransactions,
    openPostRefundTransaction
  } = useModal();
  const { setIsOpenAddService } = useModalStore();
  const { setBookingLineId } = useBookingStore();
  const [transactionIds, setTransactionIds] = useState<number[] | []>([]);
  const [code, setCode] = useState<string>('A');

  const orderId =
    orders?.length > 0 ? orders?.filter(item => item.folio_balance_code == code).at(0).id : 0;

  const handleShowAddService = () => {
    if (booking_line_id) {
      setBookingLineId(booking_line_id);
      setIsOpenAddService(true);
    }
  };

  const postRoomCharge = () => {
    if (booking_line_id) {
      openPostRoomCharge(booking_line_id);
    }
  };

  const postPayment = () => {
    if (guest_id && code && booking_line_id && orders) {
      openPostPaymentTransaction(code, booking_line_id, guest_id, orderId);
    }
  };

  const postRefund = () => {
    if (guest_id && code && booking_line_id && orders) {
      openPostRefundTransaction(code, booking_line_id, guest_id, orderId);
    }
  };

  const handleMenuClick = (menu: any) => {
    switch (menu.key) {
      case TRANSACTION_PROCESSING_ACTION.MOVE_TRANSACTION:
        if (guest_id && booking_line_id) {
          openMoveTransaction(transactionIds, booking_line_id, code, guest_id);
        }
        break;
      case TRANSACTION_PROCESSING_ACTION.VOID_TRANSACTION:
        openCancelTransactions(transactionIds);
        break;
    }
  };

  return (
    <Card className="pms-cashier__transaction">
      <p className="title">Transaction List #{guest_id}</p>
      {orders ? (
        <>
          <Tabs
            onChange={(code: string) => setCode(code)}
            items={orders.map((item: any) => ({
              key: item.folio_balance_code,
              label: `${item.folio_balance_code} - ${item.sale_order_name}`,
              children: <TransactionTable orderId={item.id} setTransactionIds={setTransactionIds} />
            }))}
          ></Tabs>
        </>
      ) : (
        <Empty />
      )}
      <div className="text-right" style={{ marginTop: 12 }}>
        <Button.Group className="flex-wrap">
          <Button className="ant-btn-secondary" onClick={() => postRoomCharge()}>
            + Room Charge
          </Button>
          <Button
            className="ant-btn-secondary"
            onClick={() => handleShowAddService()}
            disabled={!guest_id}
          >
            + HK Service
          </Button>
          <Dropdown
            menu={{ items, onClick: handleMenuClick }}
            placement="top"
            trigger={['click']}
            disabled={transactionIds.length === 0}
          >
            <Button className="ant-btn-secondary">
              Trans Processing <CaretDownFilled />
            </Button>
          </Dropdown>
          <Button className="ant-btn-secondary" onClick={() => postPayment()} disabled={!guest_id}>
            Payment
          </Button>
          <Button className="ant-btn-secondary" onClick={() => postRefund()} disabled={!guest_id}>
            Refund
          </Button>
          <Button className="ant-btn-secondary">Print Deposit</Button>
          <Button className="ant-btn-secondary">Print Paid Out</Button>
          <Button className="ant-btn-secondary">Print Invoice</Button>
        </Button.Group>
      </div>
    </Card>
  );
};
