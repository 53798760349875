import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { GetConsolidateRevenueType } from 'services/api/type/report.type';

export const useGetConsolidateRevenue = (
  filterOptions: GetConsolidateRevenueType,
  isEnable: boolean = true
) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_CONSOLIDATE_REVENUE, filterOptions],
    queryFn: () => API.report.getConsolidateRevenue(filterOptions),
    enabled: isEnable
  });

  return {
    data: data,
    isLoading
  };
};
