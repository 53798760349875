import React, { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import {
  Button,
  Card,
  DatePicker,
  DatePickerProps,
  Form,
  FormProps,
  Table,
  Input,
  TimeRangePickerProps
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useQueryClient } from '@tanstack/react-query';

import ExportCashFlow from 'components/common/export-cash-flow';
import { useGetCashFlow, useGetCashFlowAll } from 'hooks/useGetCashFlow';
import useModal from 'stores/useModal';

import QUERY_KEYS from 'services/api/queryKeys';
import { PAGE_SIZE_CASH_FLOW } from 'services/api/module/report.api';
import { formatCurrency, toUtcTime } from 'utils';
import { RangeValueType } from 'services/api/type/common.type';
import { getCashFlowColumns } from 'constants/table';
import { CASHFLOW_TYPE } from 'constants/common';
import 'styles/cash-book-detail.scss';

const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
  if (from) {
    return current.isBefore(from);
  }

  return false;
};

const rangePresets: TimeRangePickerProps['presets'] = [
  { label: 'Hôm nay', value: [dayjs(), dayjs()] },
  {
    label: 'Tuần này',
    value: [dayjs().isoWeekday(1), dayjs().isoWeekday(7)]
  },
  {
    label: 'Tháng này',
    value: [dayjs().startOf('month'), dayjs().endOf('month')]
  },
  {
    label: 'Tháng trước',
    value: [
      dayjs().startOf('month').subtract(1, 'day').startOf('month'),
      dayjs().startOf('month').subtract(1, 'day')
    ]
  },
  {
    label: 'Quý này',
    value: [dayjs().startOf('quarter'), dayjs().endOf('quarter')]
  },
  {
    label: 'Quý trước',
    value: [
      dayjs().startOf('quarter').subtract(1, 'day').startOf('quarter'),
      dayjs().startOf('quarter').subtract(1, 'day')
    ]
  },
  {
    label: '6 tháng trước',
    value: [
      dayjs().subtract(6, 'month').startOf('month'),
      dayjs().subtract(6, 'month').endOf('month')
    ]
  },
  {
    label: 'Năm nay',
    value: [dayjs().startOf('year'), dayjs().endOf('year')]
  },
  {
    label: 'Năm trước',
    value: [
      dayjs().startOf('year').subtract(1, 'day').startOf('year'),
      dayjs().startOf('year').subtract(1, 'day')
    ]
  }
];

const initStartDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
const initEndDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

function CashBookDetail() {
  const [form] = Form.useForm();
  const rangeDateForm = Form.useWatch('rangeDate', form);
  const queryClient = useQueryClient();
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  const { setIsOpenAddExpense } = useModal();
  const [filterOptions, setFilterOptions] = useState<any>({
    start_date: toUtcTime(initStartDate, 'YYYY-MM-DD HH:mm:ss'),
    end_date: toUtcTime(initEndDate, 'YYYY-MM-DD HH:mm:ss')
  });

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_CASH_FLOW);
  const { data, pagination, isFetching } = useGetCashFlow(filterOptions, page, pageSize);
  const { data: dataAll } = useGetCashFlowAll(filterOptions);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CASH_FLOW, page]
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CASH_FLOW_ALL]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, ...restFilterOptions } = truthyValues;

    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];
      if (startDate) {
        _.set(restFilterOptions, 'start_date', toUtcTime(startDate, 'YYYY-MM-DD HH:mm:ss'));
      }
      if (endDate) {
        _.set(restFilterOptions, 'end_date', toUtcTime(endDate, 'YYYY-MM-DD HH:mm:ss'));
      }
    }

    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleShowAddExpense = () => {
    setIsOpenAddExpense(true);
  };

  const handleRangeDateChange = (value: RangeValueType | null) => {
    let startDate: Dayjs | null = _.get(value, '0', null);
    let endDate: Dayjs | null = _.get(value, '1', null);
    if (startDate) {
      startDate = startDate.set('hour', 0).set('minute', 0).set('second', 0);
    }
    if (endDate) {
      endDate = endDate.set('hour', 23).set('minute', 59).set('second', 59);
    }
    form.setFieldValue('rangeDate', [startDate, endDate]);
  };

  const totalIncome = useMemo(() => {
    return dataAll
      .filter(item => item.state !== 'cancelled')
      .reduce((cur, next) => {
        return cur + (next.payment_type === CASHFLOW_TYPE.INCOME ? next.amount : 0);
      }, 0);
  }, [dataAll]);

  const totalOutcome = useMemo(() => {
    return dataAll.reduce((cur, next) => {
      return cur + (next.payment_type === CASHFLOW_TYPE.PAYMENT ? next.amount : 0);
    }, 0);
  }, [dataAll]);

  const cashFlowColumns = useMemo(() => getCashFlowColumns(page), [page]);

  return (
    <div className="pms-cash-book-detail">
      <Card style={{ width: '100%' }}>
        <Form
          form={form}
          name="cash-book-detail-form"
          layout={isIpadMobile ? 'vertical' : 'inline'}
          initialValues={{
            rangeDate: [initStartDate, initEndDate]
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-cash-book-detail__filter-wrapper">
            <div className="pms-cash-book-detail__filter">
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  presets={rangePresets}
                  className="w-full"
                  placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
                  disabledDate={disabledDate}
                  format="YYYY-MM-DD"
                  onChange={handleRangeDateChange}
                />
              </Form.Item>
              <Form.Item name="booking_id">
                <Input placeholder="Mã đặt phòng" />
              </Form.Item>
              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                Tìm kiếm
              </Button>
            </div>

            <div className="flex items-center" style={{ gap: '8px' }}>
              <Button type="primary" onClick={handleShowAddExpense}>
                Tạo phiếu chi
              </Button>
              <ExportCashFlow
                filterOptions={{
                  start_date: toUtcTime(
                    _.get(rangeDateForm, '0') || initStartDate,
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                  end_date: toUtcTime(
                    _.get(rangeDateForm, '1') || initEndDate,
                    'YYYY-MM-DD HH:mm:ss'
                  )
                }}
                fileName={`cash-book-detail-${(_.get(rangeDateForm, '0') || initStartDate)?.format(
                  'DD-MM-YYYY'
                )}-${(_.get(rangeDateForm, '1') || initEndDate)?.format('DD-MM-YYYY')}-${nanoid()}`}
              />
            </div>
          </div>
        </Form>

        <div className="pms-cash-book-detail__table">
          <Table
            loading={isFetching}
            rowKey="id"
            columns={cashFlowColumns}
            dataSource={data}
            bordered
            // onRow={(record, rowIndex) => {
            //   return {
            //     }, // click row
            //   };
            // }}
            pagination={{
              current: page,
              position: ['bottomCenter'],
              total: pagination.total,
              pageSize: pageSize,
              locale: { items_per_page: '/ trang' },
              showTotal: total => `${total} items`,
              onChange: (page: number) => handleChangePage(page),
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              }
            }}
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
            scroll={{ x: 500, y: 400 }}
          />
        </div>
      </Card>

      <div className="summary-cashflow">
        <div className="summary-cashflow__item">
          <span>I.Số dư đầu kỳ ngày:</span>
          <span>VND 0</span>
        </div>
        <div className="summary-cashflow__item">
          <span>II.Tổng tiền thu đối chiếu trong kỳ:</span>
          <span>{formatCurrency(totalIncome, 'VND')}</span>
        </div>
        <div className="summary-cashflow__item">
          <span>III.Tổng tiền chi đối chiếu trong kỳ:</span>
          <span>{formatCurrency(totalOutcome, 'VND')}</span>
        </div>
        <div className="summary-cashflow__item">
          <span>Cuối kỳ (I+II-III)</span>
          <span>{formatCurrency(totalIncome - totalOutcome, 'VND')}</span>
        </div>
      </div>
    </div>
  );
}

export default CashBookDetail;
