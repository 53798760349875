import { useMemo, useState } from 'react';
import ExcelJS from 'exceljs';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import { handleMenuClick, generateMenuItems } from 'utils/excelUtils';
import { PAYMENT_METHOD_NAMES } from 'constants/form';
import { MAP_PAYMENT_TYPE } from 'constants/text';
import { formatCurrency, toLocalTime } from 'utils';
import useCommonStore from 'stores/useCommon';
import { AllPaymentItem } from 'services/api/type/payment.type';

interface Props {
  fileName: string;
  fromDate: string;
  toDate: string;
  selectedPaymentState?: string;
  selectedPaymentMethod?: string;
  dataToExport: AllPaymentItem[];
  totalAmount: string;
  totalCustomers: number;
}

const ExportTransactions = ({
  fileName,
  dataToExport,
  fromDate,
  toDate,
  selectedPaymentState,
  selectedPaymentMethod,
  totalAmount,
  totalCustomers
}: Props) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const { currentBranch } = useCommonStore();

  const exportToCSV = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      setIsLoadingExport(true);

      const workbook = new ExcelJS.Workbook();
      const sheetName = `Transactions`;
      const sheet = workbook.addWorksheet(sheetName, {
        views: [
          {
            showGridLines: false
          }
        ]
      });

      // Title
      sheet.mergeCells('A1:J1');
      sheet.getCell('A1').value = currentBranch?.name?.toUpperCase() || '';
      sheet.getCell('A1').font = { bold: true, size: 14 };
      sheet.getCell('A1').alignment = { horizontal: 'center' };

      sheet.mergeCells('A2:J2');
      sheet.getCell('A2').value = 'BÁO CÁO GIAO DỊCH';
      sheet.getCell('A2').font = { size: 11, bold: true };
      sheet.getCell('A2').alignment = { horizontal: 'center' };

      sheet.getCell('A4').value = `Ngày`;
      sheet.getCell('A4').font = { bold: true };
      sheet.getCell('B4').value = fromDate;
      sheet.getCell('C4').value = `Đến`;
      sheet.getCell('C4').font = { bold: true };
      sheet.getCell('D4').value = toDate;
      sheet.getCell('E4').value = `Trạng thái`;
      sheet.getCell('E4').font = { bold: true };
      sheet.getCell('F4').value = selectedPaymentState || 'Tất cả';
      sheet.getCell('G4').value = `Phương thức thanh toán`;
      sheet.getCell('G4').font = { bold: true };
      sheet.getCell('H4').value = selectedPaymentMethod || 'Tất cả';

      // Header row
      sheet.getRow(5).values = [
        'ID',
        'Mã đặt phòng',
        'Ngày giao dịch',
        'Ghi chú',
        'Số tiền',
        'Mục',
        'Phương thức thanh toán',
        'Người tạo',
        'Tên khách',
        'Mô tả'
      ];
      sheet.getRow(5).eachCell(cell => {
        cell.border = {
          top: { style: 'medium', color: { argb: 'FF000000' } },
          bottom: { style: 'medium', color: { argb: 'FF000000' } },
          left: { style: 'medium', color: { argb: 'FF000000' } },
          right: { style: 'medium', color: { argb: 'FF000000' } }
        };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center' };
      });

      sheet.columns = [
        { key: 'id', width: 15 },
        { key: 'booking_id', width: 16 },
        { key: 'payment_date', width: 20 },
        { key: 'note', width: 30 },
        { key: 'amount', width: 20 },
        { key: 'payment_type', width: 15 },
        { key: 'payment_method', width: 25 },
        { key: 'user_name', width: 20 },
        { key: 'customer_name', width: 25 },
        { key: 'description', width: 90 }
      ];

      dataToExport.forEach(item => {
        const row = sheet.addRow({
          id: item.id,
          booking_id: item.booking_id,
          payment_date: toLocalTime(item.payment_date, 'DD/MM/YYYY HH:mm'),
          note: item.note,
          amount: formatCurrency(item.amount),
          payment_type: MAP_PAYMENT_TYPE[item.payment_type],
          payment_method: PAYMENT_METHOD_NAMES[item.payment_method],
          user_name: item.user_name,
          customer_name: item.customer_name,
          description: item.description
        });

        row.eachCell({ includeEmpty: true }, cell => {
          cell.border = {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' }
          };
          cell.alignment = { horizontal: 'center' };
        });
      });

      const totalRowIndex = dataToExport.length + 6;

      sheet.mergeCells(`A${totalRowIndex}:D${totalRowIndex}`);
      sheet.getCell(`A${totalRowIndex}`).value = 'Tổng Cộng';
      sheet.getCell(`A${totalRowIndex}`).font = { bold: true };
      sheet.getCell(`A${totalRowIndex}`).alignment = { horizontal: 'center' };

      sheet.getCell(`E${totalRowIndex}`).value = totalAmount;
      sheet.getCell(`E${totalRowIndex}`).font = { bold: true };
      sheet.getCell(`E${totalRowIndex}`).alignment = { horizontal: 'center' };

      sheet.getCell(`I${totalRowIndex}`).value = totalCustomers;
      sheet.getCell(`I${totalRowIndex}`).font = { bold: true };
      sheet.getCell(`I${totalRowIndex}`).alignment = { horizontal: 'center' };

      ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'].forEach(col => {
        sheet.getCell(`${col}${totalRowIndex}`).border = {
          top: { style: 'medium', color: { argb: 'FF000000' } },
          bottom: { style: 'medium', color: { argb: 'FF000000' } },
          left: { style: 'medium', color: { argb: 'FF000000' } },
          right: { style: 'medium', color: { argb: 'FF000000' } }
        };
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      return { status: 'ERROR', error: e };
    } finally {
      setIsLoadingExport(false);
    }
  };

  const handleClickMenu = (menu: any) => {
    handleMenuClick(menu.key, exportToCSV);
  };
  const items: MenuProps['items'] = useMemo(() => generateMenuItems(), []);

  return (
    <Dropdown
      menu={{ items, onClick: handleClickMenu }}
      trigger={['click']}
      overlayClassName="menu-user"
    >
      <Button icon={<DownloadOutlined />} loading={isLoadingExport}>
        Xuất dữ liệu
      </Button>
    </Dropdown>
  );
};

export default ExportTransactions;
